// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";

// import { IStaticMethods } from "preline/preline";
// Window.HSStaticMethods = IStaticMethods;
const CRiskHomeTest = () => import('@/views/c-risk/HomeText.vue')

const routes = [
  {
    path: '/',
    name: "CRiskHomeTest",
    component: CRiskHomeTest,
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
// router.beforeEach((to, from, next) => {
//   //  does the page we want to go to have a meta.progress object
//   if (to.meta.progress !== undefined) {
//     let meta = to.meta.progress;
//     // parse meta tags
//     this.$Progress.parseMeta(meta);
//   }
//   //  start the progress bar
//   this.$Progress.start();
//   //  continue to next page
//   next();
// });

router.afterEach((to, from, failure) => {
  // console.log("this")
  // console.log(this)
  if (!failure) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // setTimeout(() => {
    //   initFlowbite()
    // }, 100)

  }
  // this.$Progress.finish();

});


function checkIfAuthed(to, from, next) {
  // console.log(localStorage)
  // console.log(to)
  // console.log(from)
  // console.log(next)
  let user = localStorage.getItem('user')
  let user_type = localStorage.getItem("user_type")
  // console.log(user)
  // console.log(user_type)
  // console.log(user_type == 'user', to.path.includes('c-risk'))
  // console.log("========================")
  if (user != null) {
    next({ name: 'HomePage' })
  } else {
    next();

  }
}

function redirectIfNotAuthed(to, from, next, user_type) {
  // console.log(localStorage)
  let user = localStorage.getItem('user');
  let type = localStorage.getItem('user_type');
  // console.log(user)
  // console.log(type, user_type)
  // console.log(to.path.includes('c-risk'))
  // console.log("----------------------")
  // console.log(to)
  // console.log(from)
  // console.log(next)
  if (user === null) {
    next({ name: 'LoginView' })

  } else {
    // console.log(to.meta != null && to.meta.background_class != null)
    // console.log(to.meta)
    // console.log(to.meta.background_class)
    if (to.meta && to.meta.background_class) {
      // console.log(to.meta.background_class)
      document.body.classList.add(to.meta.background_class);
      next()
    } else {
      document.body.classList.remove('background-body');

      next()
    }

  }
}


export default router
