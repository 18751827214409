<template>
  <router-view />

</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
  },

  mounted() {

    // initFlowbite()
    // this.user = localStorage.getItem('user');

    // localStorage.setItem("vueform-dark-mode",
    //   this.isDark ? "dark" : "light"
    // )

    // console.log("in mounted")
    // console.log(this)

    // let temp = this
    // setTimeout(function () {
    //   temp.require_navbar = temp.$route.meta && temp.$route.meta.is_public && temp.$route.meta.with_navbar
    //   console.log("require_navbar", temp.$route.meta, temp.$route.meta.is_public, temp.$route.meta.with_navbar, temp.require_navbar)

    // }, 500);


  },
  created() {
    console.log("in created, app vue")
    console.log(this.$route)

    // this.require_navbar = this.$route.meta && this.$route.meta.is_public && this.$route.meta.with_navbar
    // console.log("require_navbar", this.$route.meta, this.$route.meta.is_public, this.$route.meta.with_navbar, this.require_navbar)

  },

  // created() {
  //     //  [App.vue specific] When App.vue is first loaded start the progress bar
  //     this.$Progress.start();
  //     //  hook the progress bar to start before we move router-view
  //     this.$router.beforeEach((to, from, next) => {
  //       //  does the page we want to go to have a meta.progress object
  //       if (to.meta.progress !== undefined) {
  //         let meta = to.meta.progress;
  //         // parse meta tags
  //         this.$Progress.parseMeta(meta);
  //       }
  //       //  start the progress bar
  //       this.$Progress.start();
  //       //  continue to next page
  //       next();
  //     });
  //     //  hook the progress bar to finish after we've finished moving router-view
  //     this.$router.afterEach((to, from) => {
  //       //  finish the progress bar
  //       this.$Progress.finish();
  //     });
  //   },
  updated() {
    // console.log("updated in app vue")
  },
  methods: {

    // toggleDark() {
    //   console.log("click toggle dark", this.isDark);
    //   useToggle(this.isDark)
    //   this.isDark = !this.isDark
    //   localStorage.setItem("vueform-dark-mode",
    //     this.isDark ? "dark" : "light"
    //   )
    // }
  }
}
</script>

<style>
@import './../node_modules/@vueform/builder/index.css';
@import 'datatables.net-dt';
/* @import 'datatables.net-bs4'; */
</style>