// builder.config.js
import { defineConfig, elementTypes, sections } from '@vueform/builder'
import simple from '@vueform/builder/presets/simple'

// import '@vueform/builder/index.css';
import '@vueform/builder/index.css';
// import '@/assets/css/form-custom-styles.scss'
import '@/assets/css/styles-protostars-new.scss'

import {
  TypeField,
  NameField,
  LabelField,
  InfoField,
  DescriptionField,
  BeforeField,
  BetweenField,
  AfterField,
  SizeField,
  ColumnsField,
  ConditionsField,
} from '@vueform/builder'

import {
  PlaceholderField,
  EndpointField,
  AcceptField,
  ToolsField,
  DefaultField_editor,
  SubmitField,
  FieldNameField,
  ValidationField,
  DisabledField,
  IdField,
} from '@vueform/builder'

// import CustomField from '@/components/Inputs/CustomField';

// builder.config.js

export default defineConfig({
  // transformElement: (elementSchema, builder) => {
  //   return {
  //     ...elementSchema,
  //     name: `input_${Date.now()}`
  //   }
  // },
  transformElement: (elementSchema, builder) => {
    // concdde.log(elementSchema.name, elementSchema.builder.type, elementSchema.name == elementSchema.builder.type)
    if (elementSchema.name != elementSchema.builder.type) {
      return elementSchema
    }
    else {
      return {
        ...elementSchema,
        name: `${elementSchema.name}_${Date.now()}`
      }
    }

  },

  autosave: 1000,
  ai: {
    /**
     * Enable the AI assistant. (`false` by default)
     */
    enabled: true,

    /**
     * List of messages to display when the form is being generated. ([] by default)
     * If empty, 'Please wait while your form is being generated' will be shown.
     */
    messages: [
      'Please wait while your form is being generated'
    ],

    /**
     * Whether messages should be shuffled. (`false` by default)
     */
    shuffle: true,
  },

  search: true,
  views: ['editor','preview'],//, 'preview','code'
  devices: false,// ['tablet', 'desktop'], // set to `false` to hide device selector and disable responsive column resizing
  breakpoints: {
    tablet: {
      breakpoint: 'sm',
      viewportSize: 640, // used only to inform users about the viewport size related to `tablet` view
    },
    desktop: {
      breakpoint: 'lg',
      viewportSize: 1024, // used only to inform users about the viewport size related to `desktop` view
    },
  }, // the breakpoints tied to devices
  darkMode: false,// ['light', 'dark'],//false
  toggleLeft: true,
  toggleRight: true,
  clear: true,
  save: true,
  undo: true,
  modelPreview: true,//Data model should be displayed in preview mode.
  leftPanel: ['elements', 'tree'],
  rightPanel: ['form', 'settings', 'model'],//  'theme','export'
  defaultWidth: 1440,
  locales: {},

  /**
 * Whether multiple elements should be allowed in a row. When set to `false`
 * resizing elements will only resize the element `wrapper` not the `container`
 * keeping each element in a unique row. When disabled, elements cannot be added
 * to left or right side and `container` column resizing is hidden.
 */
  autoflow: false,

  /**
* Whether to open the config panel when an element is added.
*/
  openOnAdd: true,


  // emojiFlags: true,
  // {  // default `{}`
  //   en: 'English',
  //   fr: 'French',
  //   // ...
  // }

  // List of element types to exclude
  excludeElements: [
    'checkboxTabs', 'radio', 'radioTabs', 'verticalSlider',
    'image', 'multiImage', 'gallery', 'multiselect', 'captcha',
    'html', 'signature', 'list', 'structure',
    'primaryButton', 'secondaryButton', 'dangerButton',
    'container3', 'container4', 'submit', 'reset',
    "checkboxgroup", "radiogroup", "editor"
    // "editor"
  ], // '',
  includeElements: ['color', 'quillEditor', 'address'],


  // themes: [
  //   {
  //     label: 'Official themes',
  //     themes: [
  //       {
  //         label: 'Tailwind',
  //         default: true,
  //         vars: {

  //           primary: '#f0a',
  //           'primary-darker': '#06ac8b',
  //           'space-static-tag-1': '5rem',
  //           'space-static-tag-2': '50rem',
  //           'space-static-tag-3': '100rem',
  //         }
  //       }]
  //   }],
  // theme: {
  //   extend: {
  //     colors: {
  //       primary: {
  //         500: 'purple', // primary
  //         600: 'rgb(105 0 105)' // slightly darker primary
  //       }
  //     }
  //   }
  // },

  form: {
    props: {
      properties: {
        name: true,
        width: false,
        nesting: false,
      },
      submission: {
        endpoint: false,
        formKey: false,
      },

      layout: {
        size: false,
        columns: false,
        forceLabels: false,
        floatPlaceholders: false,
      }
    }
  },

  // elements settings panel
  element: {

    props: {
      default: {
        properties: {
          placeholder: {
            floating: false,
          },
          custom: true
        },
        data: {
          submit: false,
        },
        layout: {
          size: false,
          columns: false,
        },
        decorators: {
          before: false,
          between: false,
          after: false,
        },
        validation: {
          fieldName: true,
          validation: {
            required: true,
            nullable: false,
            exists: false,
            unique: false,
            regex: false,
            uuid: false,
          }
        },

        attributes: {
          attrs: false,
          autocomplete: false,
          id: false
        }
      },

      text: {

        properties: {
          inputType: false,

        },
        validation: {
          fieldName: true,
          validation: {
            active_url: false,
            after: false,
            after_or_equal: false,
            alpha: true,
            alpha_dash: true,
            alpha_num: true,
            before: false,
            before_or_equal: false,
            date: false,
            date_equals: false,
            date_format: false,
            different: true,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: true,
            in_array: true,
            integer: false,
            ip: true,
            ipv4: true,
            ipv6: true,
            json: true,
            lt: false,
            lte: false,
            max: true,
            min: true,
            not_in: true,
            nullable: false,
            numeric: false,
            regex: false,
            required: true,
            same: true,
            size: false,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          },
        },
      },
      color: {

        properties: {
          inputType: false,
          placeholder: false,

        },
        validation: {
          fieldName: true,
          validation: false
        },
      },
      number: {
        properties: {
          inputType: false,
        },
        validation: {
          fieldName: true,
          validation: {
            active_url: false,
            after: false,
            after_or_equal: false,
            alpha: false,
            alpha_dash: false,
            alpha_num: false,
            before: false,
            before_or_equal: false,
            date: false,
            date_equals: false,
            date_format: false,
            different: false,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: true,
            in_array: true,
            integer: true,
            ip: false,
            ipv4: false,
            ipv6: false,
            json: false,
            lt: false,
            lte: false,
            max: true,
            min: true,
            not_in: true,
            nullable: false,
            numeric: true,
            regex: false,
            required: true,
            same: false,
            size: false,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,

          }
        },
      },
      email: {
        properties: {
          inputType: false,
        },
        validation: {
          fieldName: true,
          validation: {
            active_url: false,
            after: false,
            after_or_equal: false,
            alpha: false,
            alpha_dash: false,
            alpha_num: false,
            before: false,
            before_or_equal: false,
            date: false,
            date_equals: false,
            date_format: false,
            different: true,
            digits: false,
            digits_between: false,
            email: true,
            exists: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            ip: false,
            ipv4: false,
            ipv6: false,
            json: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            not_in: false,
            nullable: false,
            numeric: false,
            regex: true,
            required: true,
            same: false,
            size: false,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          }
        },
      },
      phone: {
        options: {
          unmask: false,
        },
        validation: {
          fieldName: true,
          validation: {
            completed: false
          }
        },
      },
      password: {
        properties: {
          inputType: false,
        },
        data: {
          default: false
        },
        validation: {
          fieldName: true,
          validation: {
            active_url: false,
            after: false,
            after_or_equal: false,
            alpha: false,
            alpha_dash: false,
            alpha_num: true,
            before: false,
            before_or_equal: false,
            date: false,
            date_equals: false,
            date_format: false,
            different: false,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            ip: false,
            ipv4: false,
            ipv6: false,
            json: false,
            lt: false,
            lte: false,
            max: true,
            min: true,
            not_in: false,
            nullable: false,
            numeric: false,
            regex: false,
            required: true,
            same: true,
            size: true,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          }
        }

      },
      url: {
        properties: {
          inputType: false,
        },
        validation: {
          fieldName: true,
          validation: {
            active_url: false,
            after: false,
            after_or_equal: false,
            alpha: false,
            alpha_dash: false,
            alpha_num: false,
            before: false,
            before_or_equal: false,
            date: false,
            date_equals: false,
            date_format: false,
            different: false,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            ip: false,
            ipv4: false,
            ipv6: false,
            json: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            not_in: false,
            nullable: false,
            numeric: false,
            regex: false,
            required: true,
            same: false,
            size: false,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          }
        },
      },

      location: {
        properties: {
          inputType: false,
        },
      },

      textarea: {
        validation: {
          fieldName: true,
          validation: {

            alpha: false,
            alpha_dash: false,
            alpha_num: false,
            different: false,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            ip: false,
            ipv6: false,
            json: false,
            lt: false,
            lte: false,
            max: true,
            min: true,
            not_in: false,
            nullable: false,
            numeric: false,
            regex: false,
            required: true,
            same: false,
            size: true,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          }
        }
      },

      quillEditor: {
        
        properties: {
          placeholder: false
        },
        options: {
          endpoint: false,
          accept: false,
          tools: false,
        },
        data: {
          default: false,
        },
        attributes: {
          disabled: false,
          id: false,
        },
        // validation: {
        //   fieldName: true,
        //   validation: {
        //     different: false,
        //     gt: false,
        //     gte: false,
        //     lt: false,
        //     lte: false,
        //     max: false,
        //     min: false,
        //     nullable: false,
        //     regex: false,
        //     required: true,
        //     same: false,
        //     size: false,
        //     string: false,
        //   },
        // },
      },


      checkbox: {

        layout: {
          align: false
        },
        validation: {
          fieldName: true,
          validation: {
            accepted: true,
            different: false,
            in: false,
            not_in: false,
            same: false,
          },
        },
      },
      // checkboxgroup: {
      //   data: {
      //     items: {
      //       // list: true,
      //       // json: true,
      //       endpoint: false,
      //     }
      //   },
      //   decorators: {
      //     before: true,
      //   },
      //   validation: {
      //     fieldName: true,
      //     validation: {
      //       array: false,
      //       distinct: false,
      //       max: true,
      //       min: true,
      //       nullable: false,
      //       required: true,
      //       size: true,
      //     },
      //   },

      // },
      checkboxBlocks: {
        data: {
          items: {
          //   list: true,
          //   json: true,
            endpoint: false,
          }
        },
        decorators: {
          before: true,
        },
        layout: {
          view: {
            tabs: false,
            blocks: true,
          },
        },
        validation: {
          // fieldName: true,
          // validation: {
          //   array: false,
          //   distinct: false,
          //   max: true,
          //   min: true,
          //   nullable: false,
          //   required: true,
          //   size: true,
          // },
        },
      },
      // checkboxTabs: {
      //   data: {
      //     items: {
      //       // list: true,
      //       // json: true,
      //       endpoint: false,
      //     }
      //   },
      //   decorators: {
      //     before: false,
      //   },
      //   validation: {
      //     fieldName: true,
      //     validation: {
      //       array: false,
      //       distinct: false,
      //       max: true,
      //       min: true,
      //       nullable: false,
      //       required: true,
      //       size: true,
      //     },
      //   },
      // },
      // radiogroup: {
      //   data: {
      //     items: {
      //       // list: true,
      //       // json: true,
      //       endpoint: false,
      //     }
      //   },
      //   decorators: {
      //     before: true,
      //   },
      //   layout: {
      //     view: {
      //       tabs: false,
      //       blocks: true,
      //     },
      //   },
      //   validation: {
      //     validation: {
      //       different: false,
      //       in: true,
      //       in_array: true,
      //       not_in: true,
      //       nullable: false,
      //       required: true,
      //       same: false,
      //     },
      //   }
      // },
      radioBlocks: {
        data: {
          items: {
            // list: true,
            // json: true,
            endpoint: false,
          }
        },
        decorators: {
          before: true,
        },
        layout: {
          view: {
            tabs: false,
            blocks: true,
          },
        },
        validation: {
          validation: {
            different: false,
            in: true,
            in_array: true,
            not_in: true,
            nullable: false,
            required: true,
            same: false,
          },
        }
      },
      // radioTabs: {
      //   data: {
      //     items: {
      //       // list: true,
      //       // json: true,
      //       endpoint: false,
      //     }
      //   },
      // },
      toggle: {

        layout: {
          align: false
        },
        validation: {
          fieldName: true,
          validation: {
            different: false,
            in: false,
            not_in: false,
            nullable: false,
            required: true,
            same: false,
          },
        },
      },

      select: {
        options: {
          native: false,
          search: false,
          create: false,
          // behavior: {
          //   deselect: true,
          //   clear: true,
          //   closeOnSelect: true,
          // },
          ui: {
            // caret: true,
            truncate: false,
            openDirection: false,
            limit: false,
          },
          // noOptions: true,
          // noResults: true,
        },
        data: {
          items: {
            // list: true,
            // json: true,
            endpoint: false,
            // valueKey: true,
            // labelKey: true,
            // dataKey: true,
            // searchParam: true,
            // updateOnSearch: true,
            // delay: true,
            // minChars: true,
            // resolveOnLoad: true,
            // filterResults: true,
            // clearOnSearch: true,
          },
          object: false,
          submit: false,
        },
        validation: {
          fieldName: true,
          validation: {
            alpha: false,
            alpha_dash: false,
            alpha_num: false,
            different: false,
            digits: false,
            digits_between: false,
            email: false,
            exists: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            ip: false,
            ipv4: false,
            ipv6: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            not_in: false,
            nullable: false,
            numeric: false,
            regex: false,
            required: true,
            same: false,
            size: false,
            string: false,
            timezone: false,
            unique: false,
            url: false,
            uuid: false,
          },


        },
      },

      tags: {
        options: {
          search: {
            strict: false,
            trackBy: false,
            inputType: false,
            autocomplete: false,
          },
          // create: false,
          create: {
            append: true,
            addOn: true,
          },
          behavior: {
            deselect: true,
            clear: true,
            closeOnSelect: true,
          },
          ui: {
            // caret: true,
            truncate: false,
            openDirection: false,
            limit: false,
          },
          // noOptions: true,
          // noResults: true,
        },
        data: {
          items: {
            // list: true,
            // json: true,
            endpoint: false,
            // valueKey: true,
            // labelKey: true,
            // dataKey: true,
            searchParam: false,
            // updateOnSearch: true,
            // delay: true,
            // minChars: true,
            // resolveOnLoad: true,
            // filterResults: true,
            // clearOnSearch: true,
          },
          object: false,
          // submit: false,
        },
        validation: {
          fieldName: true,
          validation: {
            array: false,
            distinct: false,
            gt: false,
            gte: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            nullable: false,
            required: true,
            size: false,
          },


        },
      },

      date: {
        options: {
          format: {
            display: true,
            value: true,
            load: false,
          },
        },
        validation: {
          fieldName: true,
          validation: {
            after: true,
            after_or_equal: true,
            before: true,
            before_or_equal: true,
            date: false,
            date_format: false,
            different: true,
            in: true,
            in_array: true,
            not_in: true,
            nullable: false,
            required: true,
            same: false,
            string: false,
          },
        },
      },
      datetime: {
        options: {
          seconds: false,
          hour24: false,
          format: {
            display: true,
            value: true,
            load: false,
          },
        },
        validation: {
          fieldName: true,
          validation: {
            after: true,
            after_or_equal: true,
            before: true,
            before_or_equal: true,
            date: false,
            date_format: false,
            different: true,
            in: false,
            in_array: false,
            not_in: false,
            nullable: false,
            required: true,
            same: false,
            string: false,
          },
        },
      },
      time: {
        options: {
          seconds: false,
          hour24: false,
          format: {
            display: true,
            value: true,
            load: false,
          },
        },

        validation: {
          fieldName: true,
          validation: {
            after: false,
            after_or_equal: false,
            before: false,
            before_or_equal: false,
            date: false,
            date_format: false,
            different: false,
            in: false,
            in_array: false,
            not_in: false,
            nullable: false,
            required: true,
            same: false,
            string: false,
          },
        },

      },
      dates: {
        options: {
          mode: true,
          format: {
            display: true,
            value: true,
            load: false,
          },
        },
        data: {
          default: true,
        },
        validation: {
          fieldName: true,
          validation: {
            after: true,
            after_or_equal: true,
            array: false,
            before: true,
            before_or_equal: true,
            date: false,
            date_format: false,
            distinct: false,
            max: true,
            min: true,
            nullable: false,
            required: true,
            size: true,
          },
        },
      },
      dateRange: {
        options: {
          format: {
            display: true,
            value: true,
            load: false,
          },
        },
        validation: {
          fieldName: true,
          validation: {
            after: true,
            after_or_equal: true,
            array: false,
            before: true,
            before_or_equal: true,
            date: false,
            date_format: false,
            distinct: false,
            max: false,
            min: false,
            nullable: false,
            required: true,
            size: false,
          },
        },
      },
      hidden: {
        properties: {
          type: true,
          name: true,
          meta: false,
        },
        data: {
          default: true,
          submit: false,
        },
        conditions: {
          conditions: true,
        },

      },
      slider: {
        options: {
          orientation: false,
          direction: false,
          tooltips: {
            merge: true,
            show: true,
            position: true,
          },
          tooltipFormat: true,
        },
        validation: {
          fieldName: true,
          validation: {
            array: false,
            different: true,
            digits: false,
            digits_between: false,
            distinct: false,
            gt: true,
            gte: true,
            in: true,
            in_array: false,
            integer: false,
            lt: true,
            lte: true,
            max: false,
            min: false,
            not_in: true,
            nullable: false,
            numeric: false,
            required: true,
            same: true,
            size: false,
            string: false,
          },
        },
      },
      rangeSlider: {
        options: {
          orientation: false,
          direction: false,
          tooltips: {
            merge: true,
            show: true,
            position: true,
          },
          tooltipFormat: true,
        },
        validation: {
          fieldName: true,
          validation: {
            array: false,
            different: false,
            digits: false,
            digits_between: false,
            distinct: false,
            gt: false,
            gte: false,
            in: false,
            in_array: false,
            integer: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            not_in: false,
            nullable: false,
            numeric: false,
            required: true,
            same: false,
            size: false,
            string: false,
          },
        },
      },

      file: {
        options: {
          autoUpload: false,
          dragAndDrop: true,
          softRemove: false,
          clickable: false,
          urls: false,
          // accept: {
          //   types: true,
          //   mimes: true,
          //   extensions: true,
          // },
          endpoints: false,
          params: false,
        },
        data: {
          default: false,
        },
        decorators: {
          before: true,
        },
        validation: {
          fieldName: true,
          validation: {
            // dimensions: true,
            file: false,
            gt: false,
            gte: false,
            image: false,
            lt: false,
            lte: false,
            // max: true,
            // mimetypes: true,
            // mimes: true,
            // min: true,
            nullable: false,
            required: true,
            size: false,
          },
        },
      },
      multifile: {
        options: {
          autoUpload: false,
          dragAndDrop: true,
          softRemove: false,
          clickable: false,
          controls: {
            // add: true,
            // remove: true,
            sort: false,
          },
          endpoints: false,
          params: false,
          urls: false,
          store: false
        },
        validation: {
          fieldName: true,
          validation: {
            array: false,
            dimensions: true,
            gt: false,
            gte: false,
            image: false,
            lt: false,
            lte: false,
            max: true,
            mimetypes: true,
            mimes: true,
            min: true,
            nullable: false,
            required: true,
            size: true,
          },
        },
      },

      ///////////// static  ///////////////////
      h1: {
        properties: {
          label: false,
          description: false,
        },
        options: {
          tag: false,
          attrs: false
        }
      },
      h2: {
        properties: {
          label: false,
          description: false,
        },
        options: {
          tag: false,
          attrs: false
        }
      },
      h3: {
        properties: {
          label: false,
          description: false,
        },
        options: {
          tag: false,
          attrs: false
        }
      },
      h4: {
        properties: {
          label: false,
          description: false,
        },
        options: {
          tag: false,
          attrs: false
        }
      },
      p: {
        properties: {
          label: false,
          description: false,
        },
        options: {
          tag: false,
          attrs: false
        }
      },
      quote: {
        // properties: {
        //   label: false,
        //   description: false,
        // },
        options: {
          tag: false,
          attrs: false
        }
      },
      img: {
        // properties: {
        //   label: false,
        //   description: false,
        // },
        options: {
          tag: false,
          attrs: false
        }

      },
      link: {
        options: {
          tag: {
            html: false,
            h1: false,
            h2: false,
            h3: false,
            h4: false,
            p: false,
            blockquote: false,
            img: false,
            link: false,
            hr: false,
          },
          attrs: false,
          link: {
            href: true,
            target: true,
          },
        },
      },
      divider: {
        properties: {
          label: false,
          tooltip: false,
          description: false,
        },
        options: {
          tag: false,
          content: false,
          img: false,
          link: false,
          attrs: false,
        },
        layout: {
          align: false
        }

      },

      ////////////// structure
      container: {
        properties: {
          description: false,
        },
        data: {
          nested: false,
        },
        decorators: {
          before: true,
          between: false,
          after: false,
        },
      },
      container2: {
        properties: {
          description: false,
        },
        data: {
          nested: false,
        },
        decorators: {
          before: true,
          between: false,
          after: false,
        },
      },
      // container3: {
      //   properties: {
      //     description: false,
      //   },
      //   data: {
      //     nested: false,
      //   },
      //   decorators: {
      //     before: true,
      //     between: false,
      //     after: false,
      //   },
      // },

      nestedList: {
        options: {
          controls: {
            add: true,
            remove: true,
            sort: false,
          },
          addText: true,
          storeOrder: true,
        },
        decorators: {
          tooltip: true,
          description: false,
          before: true,
        },
        validation: {
          fieldName: true,
          validation: {
            array: false,
            distinct: false,
            gt: false,
            gte: false,
            lt: false,
            lte: false,
            max: false,
            min: false,
            nullable: false,
            required: true,
            size: false,
          },
        },
      },


      ///custom
      // user: {
      //   data: {
      //     items: false,
      //   },
      // },
    },


    //   },
    // },
    // props: {
    //   default: {
    //     properties: {
    //       placeholder: {
    //         floating: false,
    //       },
    //     },
    //     data: {
    //       submit: false,
    //       items: {
    //         list: true,
    //         endpoint: false,
    //       },
    //     },

    //     layout: {
    //       size: false,
    //       columns: false,
    //       view: {
    //         tabs: false,
    //         blocks: true,
    //       },
    //       align: true,
    //     },

    //     validation: {
    //       validation: {
    //         // nullable: false,
    //         exists: true,
    //         max: true,
    //         min: true,
    //         size: false,
    //         regex: false,

    //         accepted: true,
    //         active_url: true,
    //         after: true,
    //         after_or_equal: true,
    //         alpha: false,
    //         alpha_dash: false,
    //         alpha_num: false,
    //         array: false,
    //         before: true,
    //         before_or_equal: true,
    //         boolean: true,
    //         date: true,
    //         date_equals: true,
    //         date_format: true,
    //         different: true,
    //         digits: true,
    //         digits_between: true,
    //         dimensions: true,
    //         width: true,
    //         height: true,
    //         minWidth: true,
    //         minHeight: true,
    //         maxWidth: true,
    //         maxHeight: true,
    //         ratio: true,
    //         distinct: true,
    //         email: true,
    //         file: false,
    //         gt: true,
    //         gte: true,
    //         image: true,
    //         in: false,
    //         in_array: false,
    //         integer: true,
    //         ip: true,
    //         ipv4: true,
    //         ipv6: true,
    //         json: true,
    //         lt: true,
    //         lte: true,
    //         mimetypes: true,
    //         mimes: true,
    //         not_in: true,
    //         nullable: true,
    //         numeric: true,
    //         required: true,
    //         same: true,
    //         string: true,
    //         timezone: true,
    //         unique: true,
    //         url: true,
    //         uuid: true,
    //       },
    //       fileRules: false
    //     },


    //     attributes: {
    //       disabled: true,
    //       readonly: false,
    //       id: false,
    //       autocomplete: false,
    //       attrs: false,
    //     },
    //     options: {
    //       native: false,
    //       search: {
    //         strict: false,
    //         trackBy: false,
    //         inputType: false,
    //         autocomplete: false,
    //       },
    //       create: false,
    //       behavior: {
    //         deselect: true,
    //         clear: true,
    //         closeOnSelect: true,
    //       },
    //       ui: false,
    //       // {
    //       //   caret: false,
    //       //   truncate: false,
    //       //   openDirection: false,
    //       //   limit: false,
    //       // },
    //       data: {
    //         object: false
    //       },
    //       noOptions: true,
    //       noResults: true,

    //       format: {
    //         display: true,
    //         value: true,
    //         // load: false,
    //       }, //date

    //       orientation: false, // slider
    //       direction: false,//slider
    //       tooltips: {
    //         merge: true,
    //         show: true,
    //         position: true,
    //       },

    //       // file 

    //       autoUpload: false,
    //       dragAndDrop: false,
    //       softRemove: false,
    //       clickable: false,
    //       endpoints: false,
    //       params: false,
    //       url: false,


    //       multipleLabel: false
    //     },

    //   },
    //   location: {
    //     attributes: {
    //       disabled: false,
    //     }
    //   },
    //   textarea: {
    //     decorators: false
    //   },
    //   editor: {
    //     options: {
    //       endpoint: false,
    //       accept: false,
    //       tools: false,
    //     },
    //     data: {
    //       default: false
    //     }
    //   },
    //   checkboxgroup: {
    //     validation: {
    //       validation: {
    //         array: true,
    //         distinct: true,
    //         max: true,
    //         min: true,
    //         nullable: false,
    //         required: false,
    //         size: true,

    //       },
    //     },
    //   },

    //   // file: {

    //   //   data: {
    //   //     default: false,
    //   //     submit: false,
    //   //   },
    //   //   layout: {
    //   //     view: false
    //   //   },
    //   //   options: {
    //   //     urls: false
    //   //   },

    //   //   validation: {
    //   //     validation: {
    //   //       required: false
    //   //     }
    //   //   },
    //   // },
    //   // multifile: {

    //   //   data: {
    //   //     default: false,
    //   //     submit: false,
    //   //   },
    //   //   layout: {
    //   //     view: false
    //   //   },
    //   //   options: {
    //   //     urls: false,
    //   //     controls: false,
    //   //     store: false,
    //   //   },

    //   //   validation: {
    //   //     validation: {
    //   //       required: false
    //   //     }
    //   //   },
    //   // },
    //   // select: {

    //   // },
    //   tags: {
    //     options: {
    //       create: {
    //         append: true,
    //         addOn: true,
    //       },
    //     }
    //   },
    //   // tags: {
    //   //   properties: {
    //   //     type: true,
    //   //     name: true,
    //   //     label: true,
    //   //     tooltip: true,
    //   //     placeholder: {
    //   //       floating: true,
    //   //     },
    //   //     description: true,
    //   //   },
    //   //   options: {
    //   //     search: {
    //   //       strict: true,
    //   //       trackBy: true,
    //   //       inputType: true,
    //   //       autocomplete: true,
    //   //     },
    //   //     create: {
    //   //       append: true,
    //   //       addOn: true,
    //   //     },
    //   //     behavior: {
    //   //       deselect: true,
    //   //       clear: true,
    //   //       closeOnSelect: true,
    //   //       hideSelected: true,
    //   //     },
    //   //     ui: {
    //   //       caret: true,
    //   //       truncate: true,
    //   //       openDirection: true,
    //   //       limit: true,
    //   //     },
    //   //     max: true,
    //   //     noOptions: true,
    //   //     noResults: true,
    //   //   },
    //   //   data: {
    //   //     items: {
    //   //       list: true,
    //   //       json: true,
    //   //       endpoint: true,
    //   //       valueKey: true,
    //   //       labelKey: true,
    //   //       dataKey: true,
    //   //       searchParam: true,
    //   //       updateOnSearch: true,
    //   //       delay: true,
    //   //       minChars: true,
    //   //       resolveOnLoad: true,
    //   //       filterResults: true,
    //   //       clearOnSearch: true,
    //   //     },
    //   //     default: true,
    //   //     object: true,
    //   //     submit: true,
    //   //   },
    //   //   decorators: {
    //   //     before: true,
    //   //     between: true,
    //   //     after: true,
    //   //   },
    //   //   layout: {
    //   //     size: {
    //   //       sm: true,
    //   //       md: true,
    //   //       lg: true,
    //   //     },
    //   //     columns: {
    //   //       container: true,
    //   //       label: true,
    //   //       wrapper: true,
    //   //     },
    //   //   },
    //   //   validation: {
    //   //     fieldName: true,
    //   //     validation: {
    //   //       array: true,
    //   //       distinct: true,
    //   //       gt: true,
    //   //       gte: true,
    //   //       lt: true,
    //   //       lte: true,
    //   //       max: true,
    //   //       min: true,
    //   //       nullable: true,
    //   //       required: true,
    //   //       size: true,
    //   //     },
    //   //   },
    //   //   conditions: {
    //   //     conditions: true,
    //   //   },
    //   //   attributes: {
    //   //     disabled: true,
    //   //     id: true,
    //   //     attrs: true,
    //   //   },
    //   // },

    //   ///////////// static  ///////////////////
    //   h1: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   h2: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   h3: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   h4: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   p: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   quote: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },
    //   link: {
    //     layout: {
    //       columns: {
    //         container: true,
    //         label: false,
    //         wrapper: false,
    //       },

    //     },
    //   },

    //   submit: {
    //     properties: {
    //       label: false,
    //       tooltip: false,
    //       description: true,
    //     },
    //   },
    //   reset: {
    //     properties: {
    //       label: false,
    //       tooltip: false,
    //       description: true,
    //     },
    //   },
    //   primaryButton: {
    //     properties: {
    //       label: false,
    //       tooltip: false,
    //       description: true,
    //     },
    //   },
    //   secondaryButton: {
    //     properties: {
    //       label: false,
    //       tooltip: false,
    //       description: true,
    //     },
    //   },
    //   dangerButton: {
    //     properties: {
    //       label: false,
    //       tooltip: false,
    //       description: true,
    //     },
    //   },

    //   // structure
    //   container: {
    //     properties: {
    //       description: false,
    //     },
    //     data: {
    //       nested: false,
    //     },
    //   },
    //   container2: {
    //     properties: {
    //       description: false,
    //     },
    //     data: {
    //       nested: false,
    //     },
    //   },
    //   container3: {
    //     properties: {
    //       description: false,
    //     },
    //     data: {
    //       nested: false,
    //     },
    //   },
    //   container4: {
    //     properties: {
    //       description: false,
    //     },
    //     data: {
    //       nested: false,
    //     },

    //     layout: {
    //       size: true,
    //       columns: true,
    //       view: {
    //         tabs: false,
    //         blocks: true,
    //       },
    //       align: false,
    //     },


    //   },

    //   list: {
    //     options: {
    //       controls: {
    //         add: true,
    //         remove: true,
    //         sort: false,
    //       },
    //       addText: true,
    //       storeOrder: true,
    //     },

    //     decorators: {
    //       description: false,
    //     },

    //     data: {
    //       default: false,
    //       submit: false,
    //     },

    //     validation: {
    //       field_name: false,
    //       validation: false
    //     }
    //   },
    //   nestedList: {
    //     options: {
    //       controls: {
    //         add: true,
    //         remove: true,
    //         sort: false,
    //       },
    //       addText: true,
    //       storeOrder: true,
    //     },

    //     decorators: {
    //       description: false,
    //     },

    //     data: {
    //       default: false,
    //       submit: false,
    //     },
    //     validation: {
    //       field_name: false,
    //       validation: false
    //     }
    //   }
    // },

    // types: {
    //   logo: {
    //     label: 'Logo',
    //     description: 'Company logo',
    //     icon: 'https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg',
    //     category: 'static',
    //     rules: [],
    //     schema: {
    //       type: 'static',
    //       content: '<img src="https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg" />'
    //     },
    //     sections: 'static',
    //     separators: 'static',
    //   },
    //   // ...
    // }

    types: {
      // default: {
        // label: 'Address',
        // description: 'Test custom address',
        // icon: 'CA',
        // category: 'fields',
        // rules: [],
        // schema: {
        //   type: 'address', // <- using our new `LogoElement` type
        // },
        // sections: 'radiogroup',
        // separators: 'radiogroup',

      //   label: 'My element',
      //   description: 'My awesome element',
      //   category: 'fields',
      //   schema: {
      //     type: 'address',
      //   },
      //   sections: {
      //     properties: {
      //       name: 'properties',
      //       label: 'section_properties',
      //       fields: {
      //         type: { type: TypeField },
      //         custom: { type: CustomField },
      //       },
      //     },
      //   },
      //   separators: {}
      // },
      // customRadioGroup:{
      //   label: 'Custom Radio',
      //   description: 'Test custom radio',
      //   icon: 'RR',
      //   category: 'fields',
      //   rules: [],
      //   schema: {
      //     type: 'customRadioGroup', // <- using our new `LogoElement` type
      //   },
      //   sections: 'radiogroup',
      //   separators: 'radiogroup',
      // },
      // select: {
      //   ...elementTypes.select,
      //   schema: {
      //     ...elementTypes.select.schema,
      //     items: [
      //       { value: 'apply', label: 'Apple' },
      //       { value: 'orange', label: 'Orange' },
      //       { value: 'pear', label: 'Pear' },
      //     ]
      //   }
      // },
      date: {
        ...elementTypes.date,
        schema: {
          ...elementTypes.date.schema,
          displayFormat: "DD MMM, YYYY",
          valueFormat: "YYYY-MM-DD",
          /** // date: true, time: true, seconds: true, hour24: true
      datetimeSeconds24: 'YYYY-MM-DD HH:mm:ss', 

      // date: true, time: true, seconds: true, hour24: false
      datetimeSeconds12: 'YYYY-MM-DD hh:mm:ss a',

      // date: true, time: true, seconds: false, hour24: true
      datetime24: 'YYYY-MM-DD HH:mm',

      // date: true, time: true, seconds: false, hour24: false
      datetime12: 'YYYY-MM-DD hh:mm a',

      // date: false, time: true, seconds: true, hour24: true
      timeSeconds24: 'HH:mm:ss',

      // date: false, time: true, seconds: true, hour24: false
      timeSeconds12: 'hh:mm:ss a',

      // date: false, time: true, seconds: false, hour24: true
      time24: 'HH:mm',

      // date: false, time: true, seconds: false, hour24: false
      time12: 'hh:mm a',

      // date: true, time: false
      date: 'YYYY-MM-DD',
    }*/
          loadFormat: "YYYY-MM-DD",
          date: true,
          time: false,
        },
      },
      datetime: {
        ...elementTypes.datetime,
        schema: {
          ...elementTypes.datetime.schema,
          displayFormat: "DD MMM, YYYY hh:mm A",
          valueFormat: "YYYY-MM-DDTHH:mm",
          loadFormat: "YYYY-MM-DD hh:mm",
          hour24: false,
          date: true,
          time: true,
        },
      },
      time: {
        ...elementTypes.time,
        schema: {
          ...elementTypes.time.schema,
          displayFormat: "hh:mm A",
          valueFormat: "HH:mm",
          loadFormat: "hh:mm A",
          hour24: false,
          // date:false,
          // time:true,
        },
      },
      dateRange: {
        ...elementTypes.dateRange,
        schema: {
          ...elementTypes.dateRange.schema,
          displayFormat: "DD MMM, YYYY",
          valueFormat: "YYYY-MM-DD",
          loadFormat: "YYYY-MM-DD",
          mode: 'range'
        },
      },
      dates: {
        ...elementTypes.dates,
        schema: {
          ...elementTypes.dates.schema,
          displayFormat: "DD MMM, YYYY",
          valueFormat: "YYYY-MM-DD",
          loadFormat: "YYYY-MM-DD",
          mode: 'multiple'
        },
      },
      // radioBlocks: {
      //   ...elementTypes.radioBlocks,
      //   schema: {
      //     ...elementTypes.radioBlocks.schema,
      //    view:"blocks"
      //   },
      // },
      // checkboxBlocks: {
      //   ...elementTypes.checkboxBlocks,
      //   schema: {
      //     ...elementTypes.checkboxBlocks.schema,
      //    view:"blocks"
      //   },
      // },

      // user: { // <- the unique id of the element
      //   label: 'User selector',
      //   description: 'Select a user from the database',
      //   icon: 'https://domain.com/user-element-icon.svg',
      //   category: 'fields',
      //   schema: { // <- default configation options
      //     type: 'select',
      //     label: 'Users',
      //     native: false,
      //     search: true,
      //     resolveOnLoad: false,
      //     minChars: 2,
      //     delay: 300,
      //     inputType: 'search',
      //     autocomplete: 'off',
      //     items: [{ "value": 1, "label": "sara" }],
      //   },
      //   sections: 'select', // <- using the configuration options of 'select'
      //   separators: 'select', // <- using the configuration options of 'select'
      // },
      color: { // <- the unique id of the element
        label: 'Color field',
        description: 'Enter color',
        // icon: ['fas', 'calendar'],
        // icon: ['fas', 'palette'],////['fas', 'palette'], //require('@/assets/images/form_builder/icons/palette-solid.svg'),
        // icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3L344 320c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>',
        category: 'fields',
        schema: { // <- default configation options
          type: 'text',
          label: 'Color',
          input_type: 'color',
          inputType: 'color',
        },
        // rules:'text',
        sections: 'text', // <- using the configuration options of 'select'
        separators: 'text', // <- using the configuration options of 'select'
      },
      quillEditor: {
        label: 'Editor',
        description: 'Text editor',
        icon: ['fas', 'italic'],//['fas', 'caret-square-down'],// require('@/assets/images/form_builder/icons/italic-solid.svg'),
        category: 'fields',
        rules: ['required'],
        schema: {
          type: 'quillEditor', // <- using our new `LogoElement` type
          label: "Editor",
          builder: {
            remove: true,  // Disables removing the element
            clone: true,   // Disables cloning the element
            move: true,    // Disables moving the element
            edit: true,    // Disabled editing the element
            resize: true,  // Disables resizing the element
          }
        },
        sections: {
          properties: {
            name: 'properties',
            label: 'section_properties',
            fields: {
              type: { type: TypeField },
              name: { type: NameField },
              label: { type: LabelField },
              tooltip: { type: InfoField },
              placeholder: { type: PlaceholderField },
              description: { type: DescriptionField },
            },
          },
          options: {
            name: 'options',
            label: 'section_editor_options',
            fields: {
              endpoint: { type: EndpointField },
              accept: { type: AcceptField },
              tools: { type: ToolsField },
            },
          },
          data: {
            name: 'data',
            label: 'section_data',
            fields: {
              default: { type: DefaultField_editor },
              submit: { type: SubmitField },
            },
          },
          decorators: {
            name: 'decorators',
            label: 'section_decorators',
            fields: {
              before: { type: BeforeField },
              between: { type: BetweenField },
              after: { type: AfterField },
            },
          },
          layout: {
            name: 'layout',
            label: 'section_layout',
            fields: {
              size: { type: SizeField },
              columns: { type: ColumnsField },
            },
          },
          validation: {
            name: 'validation',
            label: 'section_validation',
            fields: {
              fieldName: { type: FieldNameField },
              validation: { type: ValidationField },
            },
          },
          conditions: {
            name: 'conditions',
            label: 'section_conditions',
            fields: {
              conditions: { type: ConditionsField },
            },
          },
          attributes: {
            name: 'attributes',
            label: 'section_attributes',
            fields: {
              disabled: { type: DisabledField },
              id: { type: IdField },
            },
          },
        },
        separators: {
          properties: [
            ['name'],
            ['label', 'tooltip'],
            ['placeholder'],
            ['description'],
          ],
          options: [
            ['endpoint'],
            ['accept'],
            ['!', 'tools'],
          ],
          layout: [
            ['size'],
            ['columns'],
          ],
          validation: [
            ['fieldName'],
            ['validation'],
          ],
          attributes: [
            ['disabled', 'id'],
          ],
        }
      },

    }
  },

  /**{"theme":{},"form":{"nesting":true},"export":{"output":"inline","api":"options","theme":"none"},"builder":{"name":"MyForm"}} */

})