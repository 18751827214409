// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";

// import { IStaticMethods } from "preline/preline";
// Window.HSStaticMethods = IStaticMethods;
const ProtostarsHomeLayout = () => import('./src/home_pages/HomeIndex.vue')

//canvas
const AddCustomerCards = () => import('./src/value_proposition_canvas/AddCustomerCards.vue')
const CanvasView = () => import('./src/value_proposition_canvas/CanvasView.vue')

// Vue.use(Router)
const PublicForm = () => import('./src/PublicForm.vue')

// Lazy imports for questionnaire
const QuestionnaireList = () => import('./src/questionnaire/ListView.vue')
const QuestionnaireCreateForm = () => import('./src/questionnaire/CreateForm.vue')
const QuestionnairePreviewForm = () => import('./src/questionnaire/PreviewForm.vue')
const QuestionnaireVueFlow = () => import('./src/questionnaire/VueFlow.vue')

// Lazy imports for VueFlow
const TestVueFlow = () => import('./src/vue_flow/IndexTest.vue')
const FlowBuilderTest = () => import('./src/vue_flow/FlowBuilderTest.vue')

// Lazy import for clients
const RegisterView = () => import('./src/clients/RegisterView.vue')

// Lazy imports for auth
const LoginView = () => import('./src/auth/Login.vue')
const ChooseVerificationMethod = () => import('./src/auth/ChooseVerificationMethod.vue')
const LoginCode = () => import('./src/auth/LoginCode.vue')
const ForgetPasswordView = () => import('./src/auth/ForgetPassword.vue')
const ResetPasswordCode = () => import('./src/auth/ResetPasswordCode.vue')
const ResetPassword = () => import('./src/auth/ResetPassword.vue')

const ChangePassword = () => import('./src/auth/ChangePassword.vue')

const SituationAnalysisIndex = () => import('./src/situation_analysis/IndexView.vue')

const SuccessPublishForm = () => import('./src/SuccessPublish.vue')

const AuditsList = () => import('./src/project_audit/AuditsList.vue')
const CreateAudit = () => import('./src/project_audit/CreateAudit.vue')
const CreateAuditSocial = () => import('./src/project_audit/CreateAuditSocial.vue')
const AuditProgess = () => import('./src/project_audit/AuditProgess.vue')
const AuditResultsIndex = () => import('./src/project_audit/AuditResultsIndex.vue')

const DashboardIndex = () => import('./src/dashboard_reports/DashboardIndex')

// user management
const UserManagementIndex = () => import('./src/user_management/IndexUser.vue')

const QuestionnaireView = () => import('./src/questionnaire/QuestionnaireView.vue')
const QuestionnaireSubmissions = () => import('./src/questionnaire/SubmissionList.vue')
const SubmissionView = () => import('./src/questionnaire/SubmissionView.vue')

const GlossaryListIndex = () => import('./src/glossary/ListIndex.vue')

const ConversationsIndex = () => import('./src/conversations/ConversationsIndex.vue')


//////////////////// cyber grant flow
const CyberGrantRequests = () => import('./src/cyber_grant/CyberGrantRequests.vue')
const CyberGrantCreateClient = () => import('./src/cyber_grant/CreateClient.vue')
const CyberGrantOpenCases = () => import('./src/cyber_grant/OpenCases.vue')
const CyberGrantViewClientProfile = () => import('./src/cyber_grant/ViewClientProfile.vue')
const CyberGrantAuditSteps = () => import('./src/cyber_grant/audit_steps/StepsIndex.vue')
const ScheduleIndex = () => import('./src/cyber_grant/schedule_call/ScheduleIndex.vue')

////////////////////////// nist flow
const NISTFlowUploadDocuments = () => import('./src/nist_flow/UploadDocuments.vue')
const NISTFlowScanningInProgress = () => import('./src/nist_flow/ScanningInProgress.vue')
const NISTFlowScanningReport = () => import('./src/nist_flow/ScanningReport.vue')

///////////////////// calendar
const ScheduleIntroCall = () => import('./src/calendar/ScheduleIntroCall.vue')
const CallTimeSlotSelect = () => import('./src/calendar/CallTimeSlotSelect.vue')

import { initFlowbite } from "flowbite";

const routes = [
  {
    path: '/schedule_call',
    name: "CyberGrantScheduleCall",
    component: ScheduleIndex,
    meta: { is_public: true, with_navbar: false },  // Mark as public route
  },

  {
    path: '/',
    name: 'HomePage',
    component: ProtostarsHomeLayout,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
    children: [

      {
        path: '/dashboard',
        name: 'DashboardIndex',
        component: DashboardIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: '/audits',
        // component: User,
        children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: 'list',
            component: AuditsList,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            // UserPosts will be rendered inside User's <router-view>
            // when /user/:id/posts is matched
            path: 'create_audit',
            component: CreateAudit,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'create_audit/social_media/:id',
            component: CreateAuditSocial,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'create_audit/in_progress/:id',
            component: AuditProgess,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'audit_steps/:id?',
            // component: AuditSteps,
            name:'AuditResultsIndex',
            component: AuditResultsIndex,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
        ],
      },
      /////////////
      {
        path: '/questionnaires',
        // name: "QuestionnaireList",
        // component: QuestionnaireList,
        // beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
        children: [
          {
            path: '',
            name: "QuestionnaireList",
            component: QuestionnaireList,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
          },
          {
            path: 'create_questionnaire',
            name: 'QuestionnaireCreateForm',
            component: QuestionnaireCreateForm,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'preview_form/:id?',
            component: QuestionnairePreviewForm,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: ':id/vue_flow',
            component: QuestionnaireVueFlow,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },

          {
            path: ':form_id/success_publish',
            name: 'SuccessPublishQuestionnaire',
            component: SuccessPublishForm,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },

          {
            path: 'view_questionnaire/:form_id',
            name: 'QuestionnaireView',
            component: QuestionnaireView,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'view_questionnaire/:form_id/submissions',
            name: 'QuestionnaireSubmissions',
            component: QuestionnaireSubmissions,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'view_questionnaire/:form_id/submissions/:id',
            name: 'SubmissionView',
            component: SubmissionView,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },

        ]
      },
      ////////////////////
      {
        path: '/form/:id',
        name: 'PublicForm',
        meta: { is_public: true, with_navbar: true },
        component: PublicForm,
      },
      

      //vue flow
      {
        path: '/vue_flow',
        name: 'TestVueFlow',
        component: TestVueFlow,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: '/flow_builder',
        name: 'FlowBuilderTest',
        component: FlowBuilderTest,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },

      {
        path: '/success_publish',
        name: 'SuccessPublishForm',
        component: SuccessPublishForm,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },


      //// cyber grant flow
      {
        path: '/cyber_grant',
        // component: User,
        children: [
          {
            path: 'requests',
            name: "CyberGrantRequests",
            component: CyberGrantRequests,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'create_client/:request_id',
            name: "CyberGrantCreateClient",
            component: CyberGrantCreateClient,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'open_cases',
            name: "CyberGrantOpenCases",
            component: CyberGrantOpenCases,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'view_client_profile/:case_id',
            name: "CyberGrantViewClientProfile",
            component: CyberGrantViewClientProfile,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
          {
            path: 'audit_steps',
            name: "CyberGrantAuditSteps",
            component: CyberGrantAuditSteps,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
        ]
      },

      //// calendar
      {
        path: '/calendar',
        // component: User,
        children: [
          {
            path: 'schedule_call/intro_call',
            name: "ScheduleIntroCall",
            component: ScheduleIntroCall,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },
        ]
      },
          {
            path: 'meeting/:id/select_time_slot',
            name: "CallTimeSlotSelect",
            component: CallTimeSlotSelect,
            meta: { is_public: true, with_navbar: false }, 
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
          },

      ////// NIST flow
      {
        path: '/nist',
        // component: User,
        children: [
          {
            path: 'upload_documents',
            name: "NISTFlowUploadDocuments",
            component: NISTFlowUploadDocuments,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

          },
          {
            path: 'scanning_in_progress/:id',
            name: "NISTFlowScanningInProgress",
            component: NISTFlowScanningInProgress,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

          },
          {
            path: 'scanning_report/:id',
            name: "NISTFlowScanningReport",
            component: NISTFlowScanningReport,
            beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

          },
        ]
      },


      {
        path: '/user_management',
        component: UserManagementIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
      },

      {
        path: '/glossary',
        name: "GlossaryListIndex",
        component: GlossaryListIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

      },
      {
        path: '/conversations',
        name: "ConversationsIndex",
        component: ConversationsIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

      },
      {
        path: '/canvas',
        children: [{
          path: '/add_customer_needs',
          name: 'AddCustomerCards',
          component: AddCustomerCards,
          beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
        },
        {
          path: '/value_canvas',
          name: 'CanvasView',
          component: CanvasView,
          meta: {
            background_class: 'background-body'
          },
          beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
        },]
      },

      {
        path: '/situation_analysis',
        name: 'SituationAnalysisIndex',
        component: SituationAnalysisIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },


      {
        path: '/change_password',
        name: 'ChangePassword',
        component: ChangePassword,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
    ]
  },

  // canvas
  // {
  //   path: '/add_customer_needs',
  //   name: 'AddCustomerCards',
  //   component: AddCustomerCards
  // },
  // {
  //   path: '/value_canvas',
  //   name: 'CanvasView',
  //   component: CanvasView
  // },




  //client
  // {
  //   path: '/register',
  //   name: 'RegisterView',
  //   component: RegisterView,
  //   beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  // },
  //auth
  {
    path: '/protostars/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/choose_verification_method',
    name: 'ChooseVerificationMethod',
    component: ChooseVerificationMethod,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/verify_login/:method',
    name: 'LoginCode',
    component: LoginCode,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/forget_password',
    name: 'ForgetPasswordView',
    component: ForgetPasswordView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/reset_password_code',
    name: 'ResetPasswordCode',
    component: ResetPasswordCode,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },

  // {
  //   path: '/create_audit',
  //   name: 'CreateAudit',
  //   component: CreateAudit,
  //   beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  // },



]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
router.beforeEach((to, from, next) => {
  console.log("beforeEach - prototstars route")
  //   //  does the page we want to go to have a meta.progress object
  //   if (to.meta.progress !== undefined) {
  //     let meta = to.meta.progress;
  //     // parse meta tags
  //     this.$Progress.parseMeta(meta);
  //   }
  //   //  start the progress bar
  //   this.$Progress.start();
  //   //  continue to next page
  //   next();
});

router.afterEach((to, from, failure) => {
  // console.log("this")
  // console.log(this)
  if (!failure) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      initFlowbite()
    }, 100)

  }
  // this.$Progress.finish();

});


function checkIfAuthed(to, from, next) {
  // console.log(localStorage)
  // console.log(to)
  // console.log(from)
  // console.log(next)
  let user = localStorage.getItem('user')
  let user_type = localStorage.getItem("user_type")
  // console.log(user)
  // console.log(user_type)
  // console.log(user_type == 'user', to.path.includes('c-risk'))
  // console.log("========================")
  if (user != null) {
    next({ name: 'HomePage' })
  } else {
    next();

  }
}

function redirectIfNotAuthed(to, from, next, user_type) {
  // console.log(localStorage)
  let user = localStorage.getItem('user');
  let type = localStorage.getItem('user_type');
  // console.log(user)
  // console.log(type, user_type)
  // console.log(to.path.includes('c-risk'))
  // console.log("----------------------")
  // console.log(to)
  // console.log(from)
  // console.log(next)
  if (user === null) {
    if(to.meta.is_public){
      next()
    }else{
      next({ name: 'LoginView' })
    }

  } else {
    // console.log(to.meta != null && to.meta.background_class != null)
    // console.log(to.meta)
    // console.log(to.meta.background_class)
    if (to.meta && to.meta.background_class) {
      // console.log(to.meta.background_class)
      document.body.classList.add(to.meta.background_class);
      next()
    } else {
      document.body.classList.remove('background-body');

      next()
    }

  }
}


export default router
