<template>
    <li :class="item_class">
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: "DropdownItem",
    props: {
        item_class: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>