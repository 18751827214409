<template>
    <div :id="id"
        class="z-20 hidden " :class="list_class">
        <ul class="py-2" :aria-labelledby="button_id">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DropdownItemsUl",
    props: {
        id:{
            type: String,
            required: true
        },
        button_id: {
            type: String,
            required: true
        },
        list_class: {
            type: String,
            default: 'dropdown-menu',
            
        },
        ul_class: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>