<template>
    <button :id="id" 
        :data-dropdown-toggle="dropdown_id"
        :data-dropdown-placement="placement" 
        :data-dropdown-offset-distance="offset_distance" 
        :data-dropdown-offset-skidding="offset_skidding"
        :class="btn_class" type="button">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "DropdownButton",
    props: {
        id:{
            type: String,
            required: true
        },
        dropdown_id: {
            type: String,
            required: true
        },
        placement:{
            type: String,
            default:"bottom"
        },
        offset_distance:{
            type: Number,
            default:0
        },
        offset_skidding:{
            type: Number,
            default:0
        },
        btn_class:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>