// vueform.config.(js|ts)

// import en from '@vueform/vueform/locales/en'
// import tailwind from '@vueform/vueform/themes/tailwind'
// import { defineConfig } from '@vueform/vueform'
// import builder from '@vueform/builder/plugin'

// export default defineConfig({
//   theme: tailwind,
//   locales: { en },
//   locale: 'en',
//   apiKey: 'z3z3-azyu-v1bb-wjrl-ksbg',
//   plugins: [
//     builder,
//   ],
// })

// // vueform.config.js

import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/dist/tailwind'
// import tailwind from '@vueform/vueform/themes/tailwind'
import { defineConfig, SelectElement, Validator } from '@vueform/vueform'

import builder from '@vueform/builder/plugin'
import MaskPlugin from '@vueform/plugin-mask'


// import PhoneElement from '@/components/VueForm/PhoneElement.vue'
import QuillEditorElement from '@/components/VueForm/QuillEditorElement.vue'
import FileInputElement from '@/components/VueForm/FileInputElement.vue'
// import CustomElement from '@/components/VueForm/CustomElement.vue'
// import CustomElement from '@/components/Inputs/CustomElement.vue'
// import AddressElement from '@/components/Inputs/AddressElement.vue'
// import CustomRadioGroupElement from '@/components/Inputs/CustomRadioGroupElement.vue'
// import { ButtonElement } from '@vueform/vueform'

import axios from 'axios'
axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'https://microservices.bumblebeeai.io/api'
axios.defaults.baseURL = 'https://microservices-staging.protostars.ai/api/'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';


const at_least_uppercase = class extends Validator {
  get msg() {
    return 'The :attribute must contain at least one uppercase letter'
  }
  check(value) {
    return /(?=.*[A-Z])/.test(value)
  }
}
const at_least_lowercase = class extends Validator {
  get msg() {
    return 'The :attribute must contain at least one lowercase letter'
  }
  check(value) {
    return /(?=.*[a-z])/.test(value)
  }
}
const at_least_number = class extends Validator {
  get msg() {
    return 'The :attribute must contain at least one number'
  }
  check(value) {
    return /(?=.*[0-9])/.test(value)
  }
}
const at_least_special = class extends Validator {
  get msg() {
    return 'The :attribute must contain at least one special character @#?!$%^&*+=(),.{}|<>:_-'
  }
  check(value) {
    return /(?=.*[@#?!$%^&*+=(),.{}|<>:_-])/.test(value)
    // return /(?=.*?[#?!@$%^&*-])/.test(value)
  }
}

const dateDefaultFormat = {
  apply: 'DateElement',
  props: {

    displayFormat: {
      required: false,
      type: [String],
      default: null,
      '@default': 'locale.vueform.dateFormats.*',
    },
    valueFormat: {
      required: false,
      type: [String, Boolean],
      default: null,
      '@default': 'locale.vueform.dateFormats.*',
    },
    loadFormat: {
      required: false,
      type: [String],
      default: null,
      '@default': 'locale.vueform.dateFormats.*',
    },

  }
}

export default defineConfig({
  theme: tailwind,
  locales: { en },
  locale: 'en',
  // apiKey: 'z3z3-azyu-v1bb-wjrl-ksbg',
  apiKey: 'eqes-uhce-8azv-gf8y-fqrq',

  rules: {
    at_least_uppercase, at_least_lowercase, at_least_number, at_least_special
  },

  /** validateOn: Sets globally when the form should trigger validation. Values must be concatenated with |. Possible values: change and step.
       If change is present, an element will be validated when its value is changed.
       If step is present, all the elements within a step will be validated when the user tries to navigate to the next step using the Next form step control.
       The form always validates unvalidated elements before submitting data to the server. */
  validateOn: 'change|step',
  scrollToInvalid: true, //Whether to scroll to the first invalid element when the form gets submitted.

  showRequired: ['label'], //The list of element assets where an asterix * should be shown if the element has required validation rule. The possible items are label, placeholder and floating.
  scrollOnNext: true, //Whether to scroll to the top of the form on hitting Next button when using steps.
  floatPlaceholders: false, //Sets globally whether floating labels should be automatically created from placeholders (unless they are explicitly defined).
  displayErrors: false, //Sets globally whether error messages from messageBag should be displayed above the form in FormErrors component.
  displayMessages: false, // Sets globally whether form messages from messageBag should be displayed above the form in FormMessages component.


  plugins: [
    builder, MaskPlugin, dateDefaultFormat
  ],

  endpoints: {
    uploadTempFile: async function (file, el$) {
      console.log("in vueform.config")
      console.log(file)
      console.log(el$)
      console.log(el$.form$)
      let data = el$.form$.convertFormData({
        file,
      }) // befomes `FormData` object
      console.log(file)
      console.log(data)
      const formData = { 'client': 'PROTOSTARS', 'selectedfile': file };

      /* upload file */
      const response = await axios.post('filesmodule/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'client': 'PROTOSTARS'
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(percentCompleted);
          el$.progress = percentCompleted;
        }
      });
      console.log(response)
      console.log(response.data)

      return {
        id: '' + response.data.id, // the temp file identifier
        tmp: '' + response.data.id, // the temp file identifier
        originalName: response.data.original_name, // the original name of the file that will be displayed
      }
    }
  },


  replaceClasses: {

    CheckboxElement: {
      input: {
        'form-bg-checkbox': 'bg-grey-protostars-25 dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-75 dark:border-grey-protostars-100'
      },
      input_default: {
        // bg-grey-protostars-25 dark:bg-black-protostars  
        'form-bg-checkbox': 'bg-grey-protostars-25 dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-75 dark:border-grey-protostars-100',
        'checked:form-bg-primary': 'checked:bg-primary dark:checked:bg-primary-400',
        'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
      },
      input_md: {
        'form-mt-checkbox': 'my-auto',
      }
    },

    CheckboxgroupCheckbox: {
      wrapper: {
        "form-border-width-input": ['border'],
        "form-border-color-input": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100'],
      },
      "wrapper_first_sm": {
        "form-radius-large-t-sm": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_first_md": {
        "form-radius-large-t": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_first_lg": {
        "form-radius-large-t-lg": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_last_sm": {
        "form-radius-large-b-sm": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_last_md": {
        "form-radius-large-b": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_last_lg": {
        "form-radius-large-b-lg": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      "wrapper_not_last": {
        "!border-b-0": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      },
      input: {
        'form-bg-checkbox': 'bg-white dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-250 dark:border-grey-protostars-100',
        'checked:form-bg-primary': 'checked:bg-primary dark:checked:bg-primary-400',
        'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
      },
      input_default: {
        'form-bg-checkbox': 'bg-white dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-250 dark:border-grey-protostars-100',
        'checked:form-bg-primary': 'checked:bg-primary dark:checked:bg-primary-400',
        'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
      },

      "wrapper_selected": {
        "form-bg-selected": "border-grey-protostars-75 dark:border-grey-protostars-100 ",
        "border-black": "border-grey-protostars-75 dark:border-grey-protostars-100 ",
        "form-color-on-primary": "text-white"
      },
      "wrapper_unselected": {
        "form-bg-input": "border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars"
      },
      description: {
        "form-color-muted": ""
      },
      text_wrapper: {
        "ml-2": "ml-2 text-base"
      },
      "wrapper_sm": {
        "form-text-sm": "text-xs",
        "form-p-group-tabs-sm": "py-1.5 px-px"
      },
      "wrapper_md": {
        "form-text": "text-sm",
      },
      "wrapper_lg": {
        "form-text-lg": "text-base"
      },
    },
    // CheckboxgroupCheckbox_blocks: {
    //   input: {
    //     'form-bg-checkbox': 'bg-grey-protostars-25 dark:bg-black-protostars ',
    //     'form-border-color-checkbox': 'border-red-600 dark:border-grey-protostars-100',
    //     'checked:form-bg-primary': 'checked:bg-primary dark:checked:bg-primary-400',
    //     'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
    //   },
    //   input_default: {
    //     'form-bg-checkbox': 'bg-grey-protostars-25 dark:bg-black-protostars ',
    //     'form-border-color-checkbox': 'border-red-600 dark:border-grey-protostars-100',
    //     'checked:form-bg-primary': 'checked:bg-primary dark:checked:bg-primary-400',
    //     'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
    //   }

    // },

    RadiogroupRadio: {
      // wrapper: {
      //   "form-border-width-input": ['border'],
      //   "form-border-color-input": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100'],
      // },
      // "wrapper_first_sm": {
      //   "form-radius-large-t-sm": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_first_md": {
      //   "form-radius-large-t": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_first_lg": {
      //   "form-radius-large-t-lg": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_last_sm": {
      //   "form-radius-large-b-sm": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_last_md": {
      //   "form-radius-large-b": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_last_lg": {
      //   "form-radius-large-b-lg": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },
      // "wrapper_not_last": {
      //   "!border-b-0": ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white']
      // },

      input: {
        'form-bg-checkbox': 'bg-white dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-250 dark:border-grey-protostars-100',
        'checked:form-bg-primary': 'checked:bg-white dark:checked:bg-black-protostars',
        'checked:form-border-color-checked': 'checked:border-grey-protostars-250 dark:checked:border-grey-protostars-100'
      },
      input_default: {
        'form-bg-checkbox': 'bg-white dark:bg-black-protostars ',
        'form-border-color-checkbox': 'border-grey-protostars-250 dark:border-grey-protostars-100',
        'checked:form-bg-primary': 'checked:bg-white dark:checked:bg-black-protostars',
        'checked:form-border-color-checked': 'checked:border-grey-protostars-250 dark:checked:border-grey-protostars-100',
        'hover:form-bg-checkbox-hover': 'hover:bg-grey-protostars-25 dark:hover:bg-black-protostars',
        'focused:form-bg-checkbox-focus': 'focused:bg-grey-protostars-25 dark:focused:bg-black-protostars',
        'checked-focused:form-bg-primary': 'checked-focused:bg-white dark:checked-focused:bg-black-protostars',
        'checked-hover:form-bg-primary': 'checked-hover:bg-white dark:checked-hover:bg-black-protostars',
      },

      "wrapper_selected": {
        "form-bg-selected": "border-grey-protostars-75 dark:border-grey-protostars-100  dark:bg-black-protostars",
        "border-black": "border-primary dark:border-primary-400",
        "form-color-on-primary": "text-white"
      },
      "wrapper_unselected": {
        "form-bg-input": "border-grey-protostars-75 dark:border-grey-protostars-100 bg-white dark:bg-black-protostars"
      },
      description: {
        "form-color-muted": ""
      },
      description_md: {
        "form-text-small":""
      },
      text_wrapper: {
        "ml-2": "ml-2 text-sm font-medium"
      },
      "wrapper_sm": {
        "form-text-sm": "text-xs",
        "form-p-group-tabs-sm": "py-1.5 px-px"
      },
      "wrapper_md": {
        "form-text": "text-sm",
      },
      "wrapper_lg": {
        "form-text-lg": "text-base"
      },
      /**.vfb-util-props-list-el-input {
    border-width: 0 !important;
    border-bottom-width: 1px !important;
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
    border-radius: 0 !important;
    margin-bottom: 1px; */
      /**
      container: ['my-1.5'],
      wrapper: ['border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white'],
      wrapper_selected: ['bg-grey-protostars-25 dark:bg-black-protostars'],
      wrapper_unselected: ['bg-grey-protostars-25 dark:bg-black-protostars'], */
    },

    // CheckboxElement: {
    //   input_default: 'bg-white dark:bg-grey-protostars-800 border-2 border-primary dark:border-primary-400 checked:bg-primary checked:border-2 checked:border-primary dark:checked:bg-primary-400 checked:border-2 focused:outline-0 focused:shadow-none',
    //   input: 'border-2 border-primary dark:border-primary-400',
    //   text: 'cursor-pointer text-base font-normal font-Poppins text-primary-900 dark:text-grey-protostars-100 '
    // },

    SelectElement: {
      //text-grey-protostars-100 dark:text-grey-protostars-400 
      inputPlaceholder: {
        "form-color-placeholder": "text-grey-protostars-100 dark:text-grey-protostars-400 ",
      },
      select: {
        placeholder: {
          "form-color-placeholder": "text-grey-protostars-100 dark:text-grey-protostars-400 ",
        }
      }
    },

    // SelectElement: {
    //  select: {
    //     container: {
    //       'form-border-width-input': '',
    //       'form-bg-input':'bg-yellow-100',
    //       "form-border-color-input":"border-green-500"
    //     }
    //   },
    // SelectElement: {
    //   container: {
    //     'form-border-width-input': '',
    //     'form-bg-input': '',
    //     'form-border-color-input': '',
    //     "hover:form-bg-input-hover": '',
    //     "hover:form-color-input-hover": '',
    //     "hover:form-border-color-input-hover": '',
    //     "hover:form-shadow-input-hover": ''
    //   },
    //   container_default: {
    //     'form-border-width-input': '',
    //     'form-bg-input': '',
    //     'form-border-color-input': '',
    //     "hover:form-bg-input-hover": '',
    //     "hover:form-color-input-hover": '',
    //     "hover:form-border-color-input-hover": '',
    //     "hover:form-shadow-input-hover": ''
    //   }
    // },

    // },

    StaticElement: {
      content_md: {
        'form-text': "text-0.5sm font-normal text-black-protostars-500 dark:text-grey-protostars-100"
      },
      tag_a: {
        "form-color-link": 'text-primary dark:text-primary-400 text-base font-medium'
      },
      tag_blockquote: {
        "form-border-width-blockquote": "border-s-4 bg-grey-protostars-25  dark:bg-grey-protostars-800",
        "form-border-color-blockquote": " border-gray-300 dark:border-gray-500",
      },
      tag_hr: {
        "form-border-color-hr": "border-grey-protostars-75 dark:border-grey-protostars-75"
      }

    },
    ElementDescription: {
      container: { "form-color-muted": "text-grey-protostars-250 dark:text-grey-protostars-300 mb-4" },
    },

    SliderElement: {
      slider: {
        connect: {
          'form-bg-primary': 'bg-primary dark:bg-primary-400'
        },
        connects: {
          'form-bg-primary': 'bg-primary dark:bg-primary-400'
        },
        base: {
          "form-bg-passive": 'bg-grey-protostars-50 dark:bg-grey-protostars-100'
        },
        handle: {
          'form-bg-slider-handle': 'bg-primary dark:bg-primary-400'
        },
        tooltip: {
          "form-border-color-slider-tooltip": 'border-primary dark:border-primary-400',
          "form-bg-primary": 'bg-primary dark:bg-primary-400'
        }
      }
    },
    DragAndDrop: {
      icon: {
        "form-bg-primary": 'bg-primary dark:bg-primary-400',
      },
      container: {
        // 'form-bg-input': 'bg-grey-protostars-25 dark:bg-grey-protostars-900',
        'form-bg-input': 'bg-grey-protostars-25 dark:bg-black-protostars',
        // 'form-color-input':'',
        // 'form-border-width-input':'',
      },
      container_inactive: {
        "form-border-color-input": "border-grey-protostars-100 dark:border-grey-protostars-100 "
      }
    },
    ElementLoader: {
      loader: {
        "form-bg-primary": "bg-red-600"
      },
    },
    "ElementInfo": {
      "container": {
        "form-bg-info": "info-popover",
      },
      "content": {
        "bg-black": "bg-black-protostars-800 dark:bg-black-protostars-700",
        "form-text-small": "text-sm font-Poppins font-normal",
        "rounded-md": "rounded-lg"
      },
    },
    "FilePreview": {
      "percent": {
        "form-color-muted": "text-grey-protostars dark:text-grey-protostars-100"
      },
      "progressBar": {
        "form-bg-passive": "bg-grey-protostars-75 dark:bg-grey-protostars-100",
        "h-0.75": "h-1"
      },
      "progress": {
        "form-bg-primary": "bg-primary dark:bg-primary-400",
        "h-0.75": "h-1"
      },
      "remove": {
        "form-bg-passive": "bg-grey-protostars-100 dark:bg-grey-protostars-600",
        "form-text-small": "text-sm font-Poppins font-normal",
      },
      "removeIcon": {
        "form-bg-input-color": "bg-grey-protostars-25 dark:bg-black-protostars-700"
      }

      //"content": "bg-black bg-opacity-90 text-white rounded-md form-text-small py-1 px-2.5 not-italic inline-block relative"

    },
    // MultifileElement:{
    //   buttons:{
    //     "form-bg-btn-secondary":"",
    //     "form-color-btn-secondary":"",
    //     "form-border-color-btn-secondary":"",
    //   }
    // }
    ElementLabel: {
      container_vertical_md: {
        'form-pb-gutter/3': 'pb-0.5',
        // 'form-pt-0': 'mt-2'
      },
    },


    "FormTab": {
      "wrapper_active": { "form-border-color-primary": "border-primary" },
      "wrapper_inactive": { "border-transparent": "border-grey-protostars-300 dark:border-grey-protostars-200" },
    },

    ToggleElement: {
      toggle: {
        "handle": { "top-0": "top-px" },
        "handle_sm": { "form-size-toggle-handle-sm": "w-3.5 h-3.5" },
        "handle_md": { "form-size-toggle-handle": "w-4 h-4" },
        "handle_lg": { "form-size-toggle-handle-lg": "w-4.5 h-4.5" },
        "handleOn": { "left-full": "left-[calc(100%-2px)]" },
        "handleOff": { "left-0": "left-0.5" },
        "handleOnDisabled": { "left-full": "left-[calc(100%-2px)]" },
        "handleOffDisabled": { "left-0": "left-.5" },
        "toggleOn": {
          "form-bg-primary": "bg-primary dark:bg-primary-400",
          "form-border-color-primary": "border-primary dark:border-primary-400",
          "form-color-on-primary": "text-white"
        },
        "toggleOff": {
          "form-bg-passive": "bg-neutral-300 dark:bg-grey-protostars-800",
          "form-border-color-passive": "border-neutral-300 dark:border-grey-protostars-800 ",
          "form-color-passive": "text-grey-protostars dark:text-grey-protostars-100"
        }

      }
    },

    ListElement: {
      // "add": "form-shadow-btn ease-linear transition-transform transform hover:scale-105 focus:form-ring",
      "add": {
        "form-bg-primary": "bg-transparent dark:bg-transparent",
        "form-border-width-btn": "border-none",
        "form-border-color-primary": "border-none",
        "form-color-on-primary": "text-primary dark:text-primary-400",

      }

    },
    ElementAddon: {
      container: {
          "form-color-addon":"text-grey-protostars dark:text-grey-protostars-400"
        }
    },
  },
  removeClasses: {
    ElementLabel: {
      container_md: ['form-text'],
    },
    TextElement: {
      container: ['form-text'],
      input: ["with-floating:form-p-input-floating "],
      inputContainer: ["form-radius-large with-floating:form-p-input-floating form-bg-input-success form-color-input-success form-border-color-input-success hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover"]
      // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
      //   "focused:form-shadow-input-focus", "focused:form-ring",
      //   "focused-hover:form-shadow-input-hover"],
      // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
      //   "form-shadow-input-focus", "form-ring",
      //   "form-shadow-input-hover"],
      // input_focused: ["form-color-input-focus", "form-autofill-focus"]

    },
    TextareaElement: {
      input: ["with-floating:form-p-input-floating "],
      inputContainer: ["form-radius-large", "with-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "focused:form-shadow-input-focus", "focused:form-ring", "focused-hover:form-shadow-input-hover"]
      // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
      //   "focused:form-shadow-input-focus", "focused:form-ring",
      //   "focused-hover:form-shadow-input-hover"],
      // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
      //   "form-shadow-input-focus", "form-ring",
      //   "form-shadow-input-hover"],
      // input_focused: ["form-color-input-focus", "form-autofill-focus"]

    },
    SelectElement: {
      input: ["with-floating:form-p-input-floating", "form-bg-input", "form-color-input", "form-border-color-input", "focused:form-ring"],
      //   inputContainer: ["form-radius-large", "fwith-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "focused:form-shadow-input-focus", "focused:form-ring", "focused-hover:form-shadow-input-hover"],
      //   // container:["form-bg-input","form-color-input","form-border-color-input"
      //   // ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
      //   // "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
      //   // "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
      //   //  "focused-hover:form-shadow-input-hover"],
      SelectElement: {
        container: ["form-bg-input", "form-color-input",
          "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
          "hover:form-shadow-input-hover", "focused:form-ring"],
        container_default: ["form-bg-input", "form-color-input",
          "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
          "hover:form-shadow-input-hover", "focused:form-ring"]
        //     ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
        //     "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
        //     "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
        //      "focused-hover:form-shadow-input-hover"]
      },
      select: {
        container: ["form-bg-input", "form-color-input",
          "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
          "hover:form-shadow-input-hover", "focused:form-ring"],
        container_default: ["form-bg-input", "form-color-input",
          "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
          "hover:form-shadow-input-hover", "focused:form-ring"]
        //     ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
        //     "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
        //     "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
        //      "focused-hover:form-shadow-input-hover"]
      },
      //   // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
      //   //   "focused:form-shadow-input-focus", "focused:form-ring",
      //   //   "focused-hover:form-shadow-input-hover"],
      //   // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
      //   //   "form-shadow-input-focus", "form-ring",
      //   //   "form-shadow-input-hover"],
      //   // input_focused: ["form-color-input-focus", "form-autofill-focus"]

      // },
    },

    // MultifileElement: {
    //   button: ["form-bg-btn-secondary", "form-color-btn-secondary", "form-border-color-btn-secondary"],
    //   button_enabled: ["form-bg-btn-secondary", "form-color-btn-secondary", "form-border-color-btn-secondary"],

    // },
    ButtonElement: {
      button: ["form-border-width-btn", "form-bg-btn", "form-color-btn", "form-border-color-btn"],
      button_primary: ["form-bg-btn", "form-color-btn", "form-border-color-btn"],
      "button_md": ["form-p-btn","form-radius-btn","form-text"],
    },
    ToggleElement: {
      toggle: {
        // "toggle": {
        //   "container": "form-shadow-handles inline-block rounded-full outline-zero transition-input duration-200 ease-in-out focus:form-ring",
        //   "container_enabled": "hover:form-shadow-handles-hover focus:form-shadow-handles-focus focused-hover:form-shadow-handles-hover",
        // },
        container: ["focus:form-ring"],
        container_enabled: ["hover:form-shadow-handles-hover", "focus:form-shadow-handles-focus", "focused-hover:form-shadow-handles-hover"],
        // toggleOff: ["form-bg-passive", "form-border-color-passive"]
      }
    },
    EditorElement: {
      input: ['focused:form-ring', 'focused:form-shadow-input-focus', "focused:form-bg-input-focus",
        "focused:form-color-input-focus", "focused:form-border-color-input-focus", "focused:form-shadow-input-focus",
        "focused:form-ring", "form-bg-input", "form-border-color-input"],
      input_default: ['focused:form-ring', 'focused:form-shadow-input-focus', "focused:form-bg-input-focus",
        "focused:form-color-input-focus", "focused:form-border-color-input-focus", "focused:form-shadow-input-focus",
        "focused:form-ring", "form-bg-input", "form-border-color-input"],
      input_focused: ['focused:form-ring', 'focused:form-shadow-input-focus', "focused:form-bg-input-focus",
        "focused:form-color-input-focus", "focused:form-border-color-input-focus", "focused:form-shadow-input-focus",
        "focused:form-ring"],
      input_success: ['focused:form-ring', 'focused:form-shadow-input-focus'],
    },

    FormStepsControl: {
      "button": ["form-border-width-btn", "form-shadow-btn", "focus:form-ring"],

      "button_previous_enabled": "form-bg-btn-secondary form-color-btn-secondary form-border-color-btn-secondary",
      "button_previous_disabled": "form-bg-btn-secondary form-color-btn-secondary form-border-color-btn-secondary",
      "button_next_enabled": ["form-bg-primary", "form-color-on-primary", "form-border-color-btn"],
      "button_next_disabled": ["form-bg-primary", "form-color-on-primary", "form-border-color-btn"],
      "button_next_loading": ["form-border-color-btn"],
      // "button_next_loading": "text-transparent form-bg-primary form-border-color-btn form-bg-spinner-on-primary",
      "button_finish_enabled": ["form-bg-primary", "form-color-on-primary", "form-border-color-btn"],
      "button_finish_disabled": ["form-bg-primary", "form-color-on-primary", "form-border-color-btn"],
      // "button_finish_loading": "text-transparent form-bg-primary form-border-color-btn form-bg-spinner-on-primary",

    },

    ListElement: {
      "add": "form-shadow-btn ease-linear transition-transform transform hover:scale-105 focus:form-ring",
      "add_sm": "form-radius-small-sm form-text-small-sm form-p-btn-small-sm",
      "add_md": "form-radius-small form-text-small form-p-btn-small",
      "add_lg": "form-radius-small-lg form-text-small-lg form-p-btn-small-lg"
    }
    // RadiogroupRadio: {
    //   container: ['form-bg-input'],
    //   wrapper_not_last: ["!border-b-0"],
    //   "wrapper_first_sm": "form-radius-large-t-sm",
    //   "wrapper_first_md": "form-radius-large-t",
    //   "wrapper_first_lg": "form-radius-large-t-lg",
    //   "wrapper_last_sm": "form-radius-large-b-sm",
    //   "wrapper_last_md": "form-radius-large-b",
    //   "wrapper_last_lg": "form-radius-large-b-lg",
    //   wrapper: [
    //     'form-border-color-input', 'form-border-width-input', 'form-color-input'
    //   ],
    //   "wrapper_selected": "form-bg-selected",
    //   "wrapper_unselected": "form-bg-input",
    // },
    // CheckboxgroupCheckbox: {
    //   container: ['form-bg-input'],
    //   wrapper_not_last: ["!border-b-0"],
    //   "wrapper_first_sm": "form-radius-large-t-sm",
    //   "wrapper_first_md": "form-radius-large-t",
    //   "wrapper_first_lg": "form-radius-large-t-lg",
    //   "wrapper_last_sm": "form-radius-large-b-sm",
    //   "wrapper_last_md": "form-radius-large-b",
    //   "wrapper_last_lg": "form-radius-large-b-lg",
    //   wrapper: [
    //     'form-border-color-input', 'form-border-width-input', 'form-color-input'
    //   ],
    //   "wrapper_selected": "form-bg-selected",
    //   "wrapper_unselected": "form-bg-input",


    // },
    // CheckboxgroupCheckbox_blocks: {
    //   "description": ["form-color-muted"],
    // }
    // FormStepsControls: {
    //   "container":[ "justify-between"]
    // }
  },
  addClasses: {

    "Vueform": {
      "form": ["vueFormContainer"]
    },
    "ElementLayout": {
      "container": ["mb-1"],
    },
    // SelectElement: {
    //   container: ['select-element-container'],
    //   select: {
    //     container: ['select-container',],
    //   }
    // },

    // MultifileElement: {
    //   button: ['bg-grey-protostars', 'text-white', 'border-grey-protostars'],
    //   button_enabled: ['bg-grey-protostars', 'text-white', 'border-grey-protostars'],
    // },
    StaticElement: {
      tag_h1: ["text-black-protostars dark:text-white"],
      tag_h2: ["text-black-protostars dark:text-white"],
      tag_h3: ["text-black-protostars dark:text-white"],
      tag_h4: ["text-black-protostars dark:text-white"],
      tag_p: ["text-black-protostars dark:text-white"],
      tag_blockquote: ["bg-grey-protostars-25", "dark:bg-grey-protostars-800", "italic", "text-primary-900", "dark:text-grey-protostars-100 "],
    },
    EditorElement: {
      input: ['border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars focused:border-b-2 focused:border-b-yellow-protostars focused:dark:border-b-blue-protostars '],
      input_focused: ['border-b-2 border-b-yellow-protostars dark:border-b-blue-protostars'],
    },

    ButtonElement: {
      // button: ["form-border-width-btn", "form-bg-btn", "form-color-btn", "form-border-color-btn"],
      button_primary: ["bg-primary", "text-white"]
    },
    FormStepsControl: {
      "button": ["btn-protostars", "min-w-25"],

      "button_previous_enabled": ["btn-primary"],
      "button_previous_disabled": ["btn-primary"],
      "button_next_enabled": ["btn-primary"],
      "button_next_disabled": ["btn-primary"],
      // "button_next_loading": ["form-border-color-btn"],
      // "button_next_loading": "text-transparent form-bg-primary form-border-color-btn form-bg-spinner-on-primary",
      "button_finish_enabled": ["btn-primary"],
      "button_finish_disabled": ["btn-primary"],
      // "button_finish_loading": "text-transparent form-bg-primary form-border-color-btn form-bg-spinner-on-primary",
    },
    FormTabs: {
      "container": ["justify-between"],
    },
    FormTab: {
      "container": ["w-full", "text-center", "font-medium", "text-base", "font-Poppins", "uppercase", "leading-normal"],
      "wrapper_active": ["text-primary"],
      "wrapper_inactive": ["text-grey-protostars-300 dark:text-grey-protostars-200"],
    },

    ElementAddon: {
      container: ['addon-container']
    },
    // RadiogroupRadio: {
    //   container: ['my-1.5'],
    //   wrapper: [
    //     'border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white'
    //   ],
    //   wrapper_selected: ['bg-grey-c-risk-50'],
    //   wrapper_unselected: ['bg-grey-c-risk-50']
    //   // RadiogroupRadio_blocks: {
    //   //     wrapper: ['bg-blue-300']
    // },
    CheckboxElement: {
      text: 'text-base font-normal font-Poppins text-black-protostars-700 dark:text-white'
    },
    CheckboxgroupCheckbox: {
      container: ['my-1.5'],
      text: 'font-normal font-Poppins ',
      description: 'text-sm font-normal font-Poppins text-grey-protostars dark:text-grey-protostars-100',
      wrapper_unselected: "text-black-protostars-700 dark:text-white"
      // wrapper: [
      //   'border', 'border-grey-protostars-75 dark:border-grey-protostars-100', 'rounded-lg', 'bg-grey-protostars-25 dark:bg-black-protostars', 'text-base font-normal font-Poppins text-black-c-risk dark:text-white'
      // ],
      // wrapper_selected: ['bg-grey-protostars-25 dark:bg-black-protostars'],
      // wrapper_unselected: ['bg-grey-protostars-25 dark:bg-black-protostars'],
      // // text: 'text-base font-normal font-Poppins text-black-protostars-700 dark:text-white',
      // description: 'text-sm font-normal font-Poppins text-grey-protostars dark:text-grey-protostars-100'
    },
    CheckboxgroupCheckbox_blocks: {
      container: ['my-1.5'],
      // text: 'text-base font-normal font-Poppins text-black-protostars-700 dark:text-white',
      // description: 'text-sm font-normal font-Poppins text-grey-protostars dark:text-grey-protostars-100'
    },

    // RadiogroupRadio_blocks: {
    //   container: ['my-1.5'],
    // },
    RadiogroupRadio: {
      // container: ['my-1.5'],
      text: 'font-normal font-Poppins ',
      description: 'text-0.5sm font-normal font-Poppins text-grey-protostars-500 dark:text-grey-protostars-250', 
      wrapper_unselected: "text-black-protostars-700 dark:text-white",
      text_wrapper:"text-primary-900 dark:text-grey-protostars-100"
    },
    "ElementText": {
      "container_before": "text-0.5sm font-normal font-Poppins text-grey-protostars dark:text-grey-protostars-100",
    },
    ToggleElement: {
      text: 'text-base font-normal font-Poppins text-black-protostars-700 dark:text-white',
      toggle: {
        "handle": "bottom-px top-px "
      }
    },

    FilePreview: {
      upload: ["hidden"],
      "file": ['text-black-protostars dark:text-white whitespace-nowrap	overflow-hidden text-ellipsis		 '],
      "filenameStatic": ['text-wrap max-w-full mb-2'],
      "progressBar": ['mt-4', 'bottom-0']
    },

   
  },
  overrideClasses: {
    TextElement: {
      inputContainer: 'rounded w-full flex border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars    dark:text-grey-protostars-100  ',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars dark:border-b-blue-protostars bg-grey-protostars-25  rounded-none ',
      inputContainer_success: '',
      inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#394560] dark:border-[#8A8A95]"],
      inputContainer_danger: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      input: 'w-full bg-transparent h-full form-p-input form-radius-input text-0.5sm with-floating:form-p-input-floating',
      input_md: 'w-full bg-transparent h-full form-p-input form-radius-input text-0.5sm font-normal with-floating:form-p-input-floating',
      input_focused: 'rounded-none border-none',
      input_success: '',
      // input_disabled: "opacity-50"

    },
    PhoneElement: {
      inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      inputContainer_success: '',
      inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#394560] dark:border-[#8A8A95]"],
      input_focused: 'rounded-none border-none',
      input_success: ''
    },
    LocationElement: {
      inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      inputContainer_success: '',
      inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#394560] dark:border-[#8A8A95]"],
      input_focused: 'rounded-none border-none',
      input_success: ''
    },
    TextareaElement: {
      inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  disabled:bg-[#EFEFF2] disabled:border-[#DADAE0] dark:disabled:bg-[#bfc7d9] dark:disabled:border-[#8A8A95]',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 ',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      inputContainer_success: '',
      inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#242A36] dark:border-[#6F6F7A]"],
      // input:'border-none',
      input_focused: 'rounded-none border-none',
      input_success: '',
      input_disabled: ['text-[#8F9099]']
    },

    DateElement: {
      inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      inputContainer_success: '',
      input_focused: 'rounded-none border-none',
      input_success: ''
      /**input: "w-full  bg-transparent  h-full  form-p-input  form-radius-input  form-text  with-floating:form-p-input-floating  border-0  form-color-input  group-hover:form-color-input-hover  form-autofill-default"
       * inputContainer: "w-full  flex  flex-1  transition-input  duration-200  border-solid  form-border-width-input  form-shadow-input  form-input-group  group  form-radius-input  form-h-input-height  form-bg-input  form-color-input  form-border-color-input  hover:form-bg-input-hover"
 */
    },
    DatesElement: {
      inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      inputContainer_success: '',
      input_focused: 'rounded-none border-none',
      input_success: ''
    },
    SelectElement: {

      // input_focused: 'rounded-none border-none',//may needed later
      //   inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      //   inputContainer_default: 'border-grey-protostars-75 bg-grey-protostars-25 dark:bg-black-protostars',
      //   inputContainer_focused: 'border border-grey-protostars-75 border-b-2 border-b-yellow-protostars bg-grey-protostars-25  rounded-none dark:border-b-blue-protostars',
      //   inputContainer_success: '',
      //   input_success: '',// inputContainer_success: '',
      input: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  focus:rounded-none focus:border-none text-0.5sm',
      input_default: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  focus:rounded-none focus:border-none text-0.5sm',
      "input_md": "form-py-input form-pl-input form-pr-select-no-clear form-radius-input text-0.5sm form-min-h-input-height-inner with-floating:form-p-input-floating rtl:form-pr-input rtl:form-pl-select-no-clear",

      //   // input_success: '',
      //   // select:{
      //   //   container: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full flex dark:bg-black-protostars dark:border-grey-protostars-100 dark:text-grey-protostars-100  ',
      //   // }
      select: {
        container: 'relative mx-auto w-full flex justify-end items-center cursor-pointer transition-input duration-200 form-min-h-input-height border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars rounded w-full flex  dark:text-grey-protostars-100  focus:rounded-none focus:border-none',
        container_default: ' rounded w-full flex border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars  dark:text-grey-protostars-100   focus:rounded-none focus:border-none',
        container_success: ' rounded w-full flex border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars  dark:text-grey-protostars-100   focus:rounded-none focus:border-none',
        "container_md": "text-0.5sm form-radius-input form-min-h-input-height",

        dropdown: "max-h-60 absolute z-999 -left-px -right-px bottom-0 transform shadow-md form-border-width-dropdown border-solid border-grey-protostars-75 dark:border-grey-protostars-100 bg-white dark:bg-black-protostars -mt-px overflow-y-scroll flex flex-col",
        optionSelected: "bg-primary-400 dark:bg-primary-400 text-white",
        optionPointed: "bg-slate-protostars-200 dark:bg-grey-protostars-900 text-grey-protostars-900 dark:text-white ",
        optionSelectedPointed: "bg-primary-450 text-grey-protostars-75",
        noOptions: "dark:text-grey-protostars-100",
        noResults: "dark:text-grey-protostars-200",

        "search_md": "text-0.5sm form-radius-input form-pl-input form-pr-select-no-clear with-floating:form-p-input-floating rtl:form-pl-select-no-clear rtl:form-pr-input",
        "singleLabel_md": "form-py-input form-pl-input form-radius-input text-0.5sm form-min-h-input-height-inner with-floating:form-p-input-floating rtl:form-pr-input",

      }

    },
    TagsElement: {
      select: {
        container: 'relative mx-auto w-full flex justify-end items-center cursor-pointer transition-input duration-200 form-min-h-input-height border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars rounded w-full flex  dark:text-grey-protostars-100  focus:rounded-none focus:border-none',
        container_default: ' rounded w-full flex border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars  dark:text-grey-protostars-100   focus:rounded-none focus:border-none',
        container_success: ' rounded w-full flex border border-grey-protostars-75 dark:border-grey-protostars-100 bg-grey-protostars-25 dark:bg-black-protostars  dark:text-grey-protostars-100   focus:rounded-none focus:border-none',
        dropdown: "max-h-60 absolute z-999 -left-px -right-px bottom-0 transform shadow-md form-border-width-dropdown border-solid border-grey-protostars-75 dark:border-grey-protostars-100 bg-white dark:bg-black-protostars -mt-px overflow-y-scroll flex flex-col",
        optionSelected: "bg-primary-400 dark:bg-primary-400 text-white",
        optionPointed: "bg-slate-protostars-200 dark:bg-grey-protostars-900 text-grey-protostars-900 dark:text-white ",
        optionSelectedPointed: "bg-primary-450 text-grey-protostars-75",
        noOptions: "dark:text-grey-protostars-100",
        noResults: "dark:text-grey-protostars-200",
        tag: "  form-color-tag  form-border-width-tag bg-primary dark:bg-primary-400 border-primary dark:border-primary-400 font-normal  flex  items-center  whitespace-nowrap  min-w-0  rtl:pl-0  rtl:mr-0  form-radius-input-tag  form-text-small  form-py-tag  form-pl-tag  form-mr-space-tags  form-mb-space-tags  rtl:form-pr-tag  rtl:form-ml-space-tags"
      }

    },


    // ToggleElement: {
    //   text: 'text-base font-normal font-Poppins text-primary-900 dark:text-grey-protostars-100 ',

    //   toggle: {
    //     toggleOff: ["border-grey-protostars-75 dark:border-grey-protostars-800 bg-grey-protostars-25 dark:bg-grey-protostars-800"]
    //   }
    // },

    ElementLabel: {
      container: ['flex items-start text-0.5md font-normal font-Poppins text-primary-900 dark:text-white']
    },
    FileElement: {
      "button": "border border-primary-25 dark:border-grey-protostars-550 bg-primary-25 dark:bg-grey-protostars-550  inline-block transition ",
      "button_enabled": "border-primary-25 dark:border-grey-protostars-550 bg-primary-25 dark:bg-grey-protostars-550 text-primary dark:text-white cursor-pointer transition-transform transform hover:scale-105 ",
    },
    MultifileElement: {
      "button": "inline-block transition border border-primary-25 dark:border-grey-protostars-550 bg-primary-25 dark:bg-grey-protostars-550  ",
      "button_enabled": "border-primary-25 dark:border-grey-protostars-550 bg-primary-25 dark:bg-grey-protostars-550 text-primary dark:text-white  cursor-pointer transition-transform transform hover:scale-105 ",
    }

    // TextElement: {
    //   inputContainer: 'border border-grey-protostars-75 bg-grey-protostars-25 rounded w-full ',
    //   inputContainer_default: 'border-grey-protostars-75',
    //   inputContainer_focused: 'border-t-0 border-x-0 border-b-2 border-yellow-protostars rounded-b-none',
    //   input_focused:'rounded-b-none'
    // }

  },

  locationProvider: 'google',
  elements: [
    // PhoneElement, 
    // QuillEditorElement, FileInputElement, CustomElement, AddressElement, CustomRadioGroupElement
    QuillEditorElement, FileInputElement,
  ],

});

