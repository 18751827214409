// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";

// import { IStaticMethods } from "preline/preline";
// Window.HSStaticMethods = IStaticMethods;

import protostarsRoute from '@/views/protostars/router-index.js'
console.log('protostarsRoute')
console.log(protostarsRoute)
import criskRoute from '@/views/c-risk/router-index.js'
console.log('criskRoute')
console.log(criskRoute)

import { initFlowbite } from "flowbite";

const ProtostarsHomeApp = () => import('@/views/protostars/AppVue.vue');
const CRiskHomeApp = () => import('@/views/c-risk/AppVue.vue');


const routes = [
  //  ...protostarsRoute.options.routes

  {
    path: '/',
    name: 'ProtostarsHomeApp',
    component: ProtostarsHomeApp,
    meta: { client: 'PROTOSTARS' },
    children: protostarsRoute.options.routes
  },
  {
    path: '/c-risk',
    name: 'CRiskHomeApp',
    component: CRiskHomeApp,
    meta: { client: 'CRISK' },
    children: criskRoute.options.routes
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior: function (to) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash
  //     }
  //   }
  // },
});
// Global Route Guard
router.beforeEach((to, from, next) => {
  console.log("beforeEach")
  console.log(window.location)
  let user = JSON.parse(localStorage.getItem('user')); // Get user's sub-app
  if (user) {
    // console.log(user.client, "----", to.meta.client)
    if (to.meta.client && to.meta.client !== user.client) {
      console.log("Redirecting", getUserClientSubApp())
      // Redirect if the user is trying to access an unauthorized sub-app
      // return next({ path: `/${userSubApp}` }); // Redirect to the user's allowed sub-app
      // return next({ name: `${user.client}HomeApp` }); // Redirect to the user's allowed sub-app
      return next({ name: getUserClientSubApp() }); // Redirect to the user's
    } else {
      next(); // Redirect to the user's
    }
  }else{
next()
  }
});
// router.beforeEach((to, from, next) => {
//   //  does the page we want to go to have a meta.progress object
//   if (to.meta.progress !== undefined) {
//     let meta = to.meta.progress;
//     // parse meta tags
//     this.$Progress.parseMeta(meta);
//   }
//   //  start the progress bar
//   this.$Progress.start();
//   //  continue to next page
//   next();
// });

router.afterEach((to, from, failure) => {
  // console.log("this")
  // console.log(this)
  if (!failure) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      initFlowbite()
    }, 100)

  }
  // this.$Progress.finish();

});


function checkIfAuthed(to, from, next) {
  // console.log(localStorage)
  // console.log(to)
  // console.log(from)
  // console.log(next)
  let user = localStorage.getItem('user')
  let user_type = localStorage.getItem("user_type")
  // console.log(user)
  // console.log(user_type)
  // console.log(user_type == 'user', to.path.includes('c-risk'))
  // console.log("========================")
  if (user != null) {
    next({ name: 'HomePage' })
  } else {
    next();

  }
}

function redirectIfNotAuthed(to, from, next, user_type) {
  // console.log(localStorage)
  let user = localStorage.getItem('user');
  let type = localStorage.getItem('user_type');
  // console.log(user)
  // console.log(type, user_type)
  // console.log(to.path.includes('c-risk'))
  // console.log("----------------------")
  // console.log(to)
  // console.log(from)
  // console.log(next)
  if (user === null) {
    next({ name: 'LoginView' })

  } else {
    // console.log(to.meta != null && to.meta.background_class != null)
    // console.log(to.meta)
    // console.log(to.meta.background_class)
    if (to.meta && to.meta.background_class) {
      // console.log(to.meta.background_class)
      document.body.classList.add(to.meta.background_class);
      next()
    } else {
      document.body.classList.remove('background-body');

      next()
    }

  }
}

const getUserClientSubApp = () => {
  const user = JSON.parse(localStorage.getItem('user')); // Example: User data stored in localStorage
  return user && user.client == 'CRISK' ? 'CRiskHomeApp' : 'ProtostarsHomeApp'; // Default to PROTOSTARS
};

export default router
